@import '../../sass/_variables.scss';
@import '../../sass/_fonts.scss';

.myWorkTitle {
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: OpenSauceSansBold;
    font-size: 1.25rem;
}

.myWorkSubtitle {
    margin: 0 0 1rem;
    font-family: OpenSauceSansBold;
    font-size: 1.125rem;
}

.myWorkText {
    margin: 0 0 1rem;

    &:last-of-type {
        margin: 0;
    }
}

.portfolioLink {
    font-family: OpenSauceSansBold;
    display: inline-flex;
    gap: .25rem;
    padding: 0 0 .25rem;
    text-decoration: underline;
    color: $teal;
    outline-color: $teal;
    margin: 0 0 1rem;

    &:visited {
      color: $teal;
      &:hover {
        color: $teal;
      }
    }
}

.storybookLink {
    outline-color: $teal;
    color: $teal;
    font-weight: bold;

    &:hover {
        color: $teal;
    }

    &:visited {
        color: $teal;
    }
}

.myWorkSections {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
}