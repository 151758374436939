@font-face {
    font-family: LoubagRegular;
    src: url(../fonts/Loubag-Regular.ttf);
  }
  
  @font-face {
    font-family: OpenSauceSansLight;
    src: url(../fonts/OpenSauceSans-Light.ttf);
  }
  
  @font-face {
    font-family: OpenSauceSansMedium;
    src: url(../fonts/OpenSauceSans-Medium.ttf);
  }
  
  @font-face {
    font-family: OpenSauceSansRegular;
    src: url(../fonts/OpenSauceSans-Regular.ttf);
  }

  @font-face {
    font-family: OpenSauceSansBold;
    src: url(../fonts/OpenSauceSans-Bold.ttf)
  }