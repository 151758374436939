@import '../../sass/_variables.scss';
@import '../../sass/_fonts.scss';

.educationContainer {
    display: flex;
    flex-flow: row wrap;
    gap: .5rem;
}

.educationCard {
    width: 100%;
    padding: .25rem;
}

.educationCardTitle {
    font-family: OpenSauceSansBold;
    font-size: 1.25rem;
}

.educationCardTitle {
    margin: 0 0 .5rem;
}

.educationCardText {
    margin: 0;
}